<template>
  <div>
    <div v-for="(item, index) in profile" :key="index" :class="[{'brdr_top': index !== 0}]" class="mbt_16">
      <p class="medium_bold_l" :class="[{'mt_16': index !== 0}]">{{$t('educate')}} ({{index + 1}}):</p>
      <div class="settings_main_educate_add_degree mt_24">
        <p class="medium_m">{{$t('degree_name')}}:*</p>
        <div class="settings_main_educate_add_degree_block">
          <button
            v-for="(elem, index) in degree"
            :key="elem"
            @click="selectDegree(item, index)"
            class="brdr_r_8 medium_m"
            :class="item.degree === index ? 'bttn-primary' : 'bttn_not_select'">
            {{elem}}
          </button>
        </div>
      </div>
      <div class="mt_12 settings_main_educate_add_profession">
        <p class="medium_m">{{$t('profession')}}:*</p>
        <div
          class="flex course_add_block_form_up_info course_add_filed_width">
          <div class="info">
            <label
              for="profession_name"
              ref="profession_name"
              class="m_auto_bt_12"
              :class="[item.profession_name !== '' ? 'selected_label' : 'not_selected_label']">{{$t('profession_name')}}</label>
            <input
              id="profession_name"
              type="text"
              v-model="item.profession_name"
              class="medium_m brdr_r_8"
              @click.stop>
          </div>
        </div>
      </div>
      <div class="mt_12 settings_main_educate_add_period">
        <p class="medium_m">{{$t('period_of_study')}}:*</p>
        <div @click="selectEduInd(index)" class="flex course_add_filed_width settings_main_educate_add_period_item">
          <div
            class="flex course_add_block_form_up_info">
            <SelectedComponent
              type="year"
              :title="$t('start')"
              :options="year"
              :selected="item.start"
              @select-item="selectStartYear"
              class="width_years"/>
          </div>
          <p class="m_auto_bt_12">-</p>
          <div
            class="flex course_add_block_form_up_info">
            <SelectedComponent
              type="year"
              :title="$t('ends')"
              :options="year"
              :selected="item.end"
              @select-item="selectEndYear"
              class="width_years"/>
          </div>
        </div>
      </div>
      <div class="mt_12 settings_main_educate_add_period">
        <p class="medium_m">{{$t('avg_rate')}}:*</p>
        <div class="flex course_add_filed_width">
          <div
            class="flex course_add_block_form_up_info">
            <div class="info">
              <label
                for="assessment_form"
                ref="assessment_form"
                class="form_assessment_p m_auto_bt_12"
                :class="[item.assessment_form !== '' ? 'selected_label' : 'not_selected_label']">{{$t('form_of_rate')}}</label>
              <input
                id="assessment_form"
                type="text"
                v-model="item.assessment_form"
                class="medium_m brdr_r_8"
                @click.stop>
            </div>
          </div>
          <p class="m_auto_bt_12">-</p>
          <div
            class="flex course_add_block_form_up_info">
            <div class="info">
              <label
                for="score"
                ref="score"
                class="m_auto_bt_12"
                :class="[item.score !== '' ? 'selected_label' : 'not_selected_label']">{{$t('rate')}}</label>
              <input
                id="score"
                type="number"
                v-model="item.score"
                class="medium_m brdr_r_8"
                @click.stop>
            </div>
          </div>
        </div>
      </div>
      <div v-if="index !== 0" style="padding-bottom: 16px;" class="">
        <button @click="delLineEdu(index)" class="medium_bold_m bttn-danger course_add_bttn bttn_width bttn brdr_r_8">
          <p>{{$t('delete')}}</p>
        </button>
      </div>
    </div>
    <div>
      <button @click="addLineEdu" class="medium_bold_m bttn-secondary course_add_bttn bttn_width bttn brdr_r_8">
        <p>{{$t('add_edu')}}</p>
      </button>
    </div>
    <button
      @click="checkFields"
      class="medium_bold_m bttn-primary bttn brdr_r_8 settings_main_educate_add_bttn mt_12 mr_16">
      {{$t('next')}}
    </button>
  </div>
</template>

<script>
import SelectedComponent from '@/components/select/SelectedComponent'

export default {
  name: 'EducationComponent',
  components: { SelectedComponent },
  props: {
    education: Array
  },
  data () {
    return {
      year: [],
      selectedEdu: null,
      profile: [{
        degree: 0,
        profession_name: '',
        start: '',
        end: '',
        assessment_form: '',
        score: 0,
        user: JSON.parse(localStorage.getItem('uData')).id
      }],
      degree: [`${this.$t('school')}`, `${this.$t('bachelor')}`, `${this.$t('magistracy')}`, `${this.$t('phd')}`, `${this.$t('college')}`]
    }
  },
  watch: {
    education: function () {
      this.setEdu()
    }
  },
  methods: {
    setEdu () {
      this.profile = [...this.education]
    },
    selectEduInd (ind) {
      this.selectedEdu = ind
    },
    selectStartYear (year) {
      this.profile[this.selectedEdu].start = year
    },
    selectEndYear (year) {
      this.profile[this.selectedEdu].end = year
    },
    selectDegree: function (item, index) {
      item.degree = index
    },
    generateYear () {
      for (let y = 1995; y < 2044; y++) {
        this.year.push(String(y))
      }
    },
    addLineEdu () {
      if (this.profile.length <= 5) {
        this.profile.push({
          degree: 0,
          profession_name: '',
          start: '',
          end: '',
          assessment_form: '',
          score: 0,
          user: JSON.parse(localStorage.getItem('uData')).id
        })
      } else {
        this.$emit('warn-toast', `${this.$t('count_of_massive_course')}`)
      }
    },
    checkFields: function () {
      if (this.profile[0].profession_name === '') {
        this.$emit('error-toast', `${this.$t('fill_profession_name')}`)
      } else if (this.profile[0].start === '') {
        this.$emit('error-toast', `${this.$t('fill_start_period')}`)
      } else if (this.profile[0].assessment_form === '') {
        this.$emit('error-toast', `${this.$t('fill_form_of_rate')}`)
      } else {
        this.sendEduData()
      }
    },
    delLineEdu (ind) {
      this.profile.splice(ind, 1)
    },
    sendEduData () {
      this.$emit('set-data', this.profile)
    }
  },
  mounted () {
    this.generateYear()
    this.setEdu()
  }
}
</script>
