<template>
  <div class="">
    <div class="mt_12 settings_main_educate_add_profession">
      <div v-for="(elem, index) in profile" :key="index"
        @click="setRow(index)" :class="[{'brdr_top': index !== 0}]" class="mbt_16">
        <p class="medium_bold_l" :class="[{'mt_16': index !== 0}]">{{$t('my_language_know')}} ({{index + 1}}):</p>
        <div
          class="flex course_add_block_form_up_info course_add_filed_width">
          <p class="medium_m">{{$t('my_language_know_lang_input')}}:*</p>
          <div class="info">
            <SelectedComponent
              type="certificate"
              :title="$t('my_language_know_lang_input_select')"
              :options="listOfLang"
              :selected="selectedLang[index].lang"
              @select-item="selectLang"
              class="p_margin"/>
          </div>
        </div>
        <div class="mt_12 settings_main_educate_add_profession">
          <p class="medium_m">{{$t('my_language_know_lang_input_level')}}:*</p>
          <div
            class="flex course_add_block_form_up_info course_add_filed_width">
            <div class="info">
              <SelectedComponent
                type="certificate"
                :title="$t('my_language_know_lang_input_level')"
                :options="listOfLangLevel"
                :selected="selectedLangLvl[index].lvl"
                @select-item="selectLangLevel"
                class="p_margin"/>
            </div>
          </div>
        </div>
        <div v-if="index !== 0" style="padding-bottom: 16px;" class="">
          <button @click="delLineLang(index)" class="medium_bold_m bttn-danger course_add_bttn bttn_width bttn brdr_r_8">
            <p>{{$t('delete')}}</p>
          </button>
        </div>
      </div>
    </div>
    <div>
      <button @click="addLineLang" class="medium_bold_m bttn-secondary course_add_bttn bttn_width bttn brdr_r_8">
        <p>{{$t('my_language_know_add')}}</p>
      </button>
    </div>
    <button
      @click="stepBack"
      class="medium_bold_m bttn bttn-primary brdr_r_8 settings_main_educate_add_bttn mt_12 mr_16">
      {{$t('back')}}
    </button>
    <button
      @click="checkFields"
      class="medium_bold_m bttn bttn-primary brdr_r_8 settings_main_educate_add_bttn mt_12 mr_16">
      {{$t('next')}}
    </button>
  </div>
</template>

<script>
import SelectedComponent from '@/components/select/SelectedComponent'

export default {
  name: 'LanguageComponent',
  components: { SelectedComponent },
  props: {
    languages: Array
  },
  data () {
    return {
      selectedRow: null,
      selectedLang: [{ lang: null }, { lang: null }, { lang: null }, { lang: null }, { lang: null }],
      selectedLangLvl: [{ lvl: null }, { lvl: null }, { lvl: null }, { lvl: null }, { lvl: null }],
      profile: [{
        language: '',
        level: '',
        id: null
      }],
      listOfLang: ['Казахский', 'Русский', 'Английский', 'Турецкий', 'Итальянский', 'Немецкий', 'Французский'],
      listOfLangLevel: ['A2 - Элементарный', 'В1 - Средний', 'В2 - Средне-продвинутый', 'С1 - Продвинутый', 'С2 - В совершенстве']
    }
  },
  watch: {
    languages: function () {
      this.setLang()
    }
  },
  methods: {
    setLang () {
      this.profile = this.languages
      this.profile.forEach((element, index) => {
        this.selectedLang[index].lang = this.listOfLang.findIndex(obj => obj === element.language)
        this.selectedLangLvl[index].lvl = this.listOfLangLevel.findIndex(obj => obj === element.level)
      })
    },
    setRow (ind) {
      this.selectedRow = ind
    },
    addLineLang () {
      if (this.profile.length <= 5) {
        this.profile.push({
          language: '',
          level: '',
          id: null
        })
      } else {
        this.$emit('warn-toast', `${this.$t('count_of_massive_course')}`)
      }
    },
    delLineLang (ind) {
      this.profile.splice(ind, 1)
    },
    selectLang (ind) {
      this.profile[this.selectedRow].language = this.listOfLang[ind]
      this.selectedLang[this.selectedRow].lang = ind
    },
    selectLangLevel (ind) {
      this.profile[this.selectedRow].level = this.listOfLangLevel[ind]
      this.selectedLangLvl[this.selectedRow].lvl = ind
    },
    stepBack () {
      this.$emit('step-back')
    },
    checkFields: function () {
      if (this.profile[0].language === '') {
        this.$emit('error-toast', `${this.$t('fill_lang_level_name')}`)
      } else if (this.profile[0].level === '') {
        this.$emit('error-toast', `${this.$t('fill_lang_level')}`)
      } else {
        this.sendLangData()
      }
    },
    sendLangData () {
      this.$emit('set-data', this.profile)
    }
  },
  mounted () {
    this.setLang()
  }
}
</script>
