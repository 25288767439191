<template>
  <div class="user" v-click-outside="hide">
    <div class="user_block flex" @click="openDropdown">
      <div class="user_block_ava">
        <img
          style="border-radius: 50%;"
          :src="avatar"
          alt="user"
          class=""
          v-if="avatar !== '' && avatar !== null">
        <p v-else>{{username}}</p>
      </div>
      <div class="user_block_text">
        <p class="role_text">{{role}}</p>
        <p class="username_text">{{uData.user}}</p>
      </div>
      <div class="user_block_icon" :class="[isDropdownOpen ? 'rotate' : '']">
        <img src="/icons/Vector 12 (Stroke).svg" alt="arrow" class="">
      </div>
    </div>
    <div class="user_dropdown" v-if="isDropdownOpen">
      <ul class="brdr_r_8 border_gray_2 bckg_white">
        <li @click="goProfileSetting" class="medium_s flex" style="border-bottom: 1px solid var(--gray-2)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="7" r="4" fill="#004DE7"/>
          <path d="M12 13C8.26504 13 5.12788 15.5595 4.24685 19.0202C3.97435 20.0907 4.89543 21 6 21H18C19.1046 21 20.0257 20.0907 19.7531 19.0202C18.8721 15.5595 15.735 13 12 13Z" fill="#004DE7"/>
          </svg>
          <p>{{$t('my_profile')}}</p>
        </li>
        <li class="medium_s flex" @click="logout">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 5.22222C4 4.54721 4.54721 4 5.22222 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H6V18H14C14.5523 18 15 18.4477 15 19C15 19.5523 14.5523 20 14 20H5.22222C4.54721 20 4 19.4528 4 18.7778V5.22222Z" fill="#004DE7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1117 8.23431C16.8411 7.9219 16.4024 7.9219 16.1318 8.23431C15.8612 8.54673 15.8612 9.05327 16.1318 9.36569L17.7206 11.2H9.69289C9.31022 11.2 9 11.5582 9 12C9 12.4418 9.31022 12.8 9.69289 12.8H17.7205L16.1318 14.6343C15.8612 14.9467 15.8612 15.4533 16.1318 15.7657C16.4024 16.0781 16.8411 16.0781 17.1117 15.7657L19.7363 12.7354C20.088 12.3292 20.088 11.6708 19.7363 11.2646L17.1117 8.23431Z" fill="#004DE7"/>
          </svg>
          <p>{{$t('exit')}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersBlock',
  data () {
    return {
      username: '',
      role: '',
      isDropdownOpen: false,
      uData: JSON.parse(localStorage.getItem('uData')),
      avatar: ''
    }
  },
  methods: {
    openDropdown: function () {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    hide: function () {
      this.isDropdownOpen = false
    },
    goProfileSetting: function () {
      localStorage.setItem('nb', 9)
      this.$router.push('/settings')
      this.$emit('my-profile')
      this.hide()
    },
    selectRole: function () {
      switch (this.uData.rle) {
        case 'admin_school': this.role = `${this.$t('admin_school')}`
          break
        case 'admin': this.role = `${this.$t('manager')}`
          break
        case 'teacher': this.role = `${this.$t('teacher')}`
          break
        case 'student': this.role = `${this.$t('student')}`
          break
      }
    },
    // axios functions
    async logout () {
      const cookieKeys = ['abtCourseTabsSelect', '_schlCrsPgSv', '_schlLssnPgSv', '_usrLstPgSv', '_crsCtlgPgSv', '_usrLstSrchSv', '_usrLstCrsSv', '_usrLstLgnSv', '_usrLstTbsSv', '_crsCtlgCtgrSv', '_crsCtlgDgrSv', '_crsCtlgCtSv', '_crsCtlgFrmtSv', '_crsCtlgLnggSv', '_crsCtlgSrchSv', '_crsCtlgFcltySv', '_crsCtlgUnvSv', '_prgrmFcltSv', '_prgrmUnvrSv', '_crsGstCtlgCtSv', '_crsGstCtlgLngSv', '_crsGstCtlgUnvrSv', '_crsGstCtlgFrmtSv', '_crsGstCtlgFcltSv', '_prgrmCtSv', '_prgrmLngSv', '_prgrmFrmtSv', '_prgrmDgrSv', '_crsGstCtlgDgrSv', '_prgrmCtgrSv']
      cookieKeys.forEach((element) => {
        if (this.$cookies.isKey(element)) { this.$cookies.remove(element) }
      })
      await this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/guest-catalog')
        })
    },
    async getUser () {
      await this.$store.dispatch('getProfile')
        .then((response) => {
          this.username = this.uData.user.trim().substring(0, 1) + response.data[0].last_name.trim().substring(0, 1)
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    async getAvatar () {
      await this.$store.dispatch('getAvatarProfile', this.uData.id)
        .then((response) => {
          this.avatar = response.data[0].avatar
        })
        .catch(err => {
          console.log(err.response.data)
        })
    }
  },
  mounted () {
    this.getUser()
    this.getAvatar()
    this.selectRole()
  }
}
</script>
