<template>
  <div>
    <div
      v-for="(item, index) in certif" :key="item + index"
      :class="[{'brdr_top': index !== 0}]"
      class="mbt_16"
      @click="setRow(index)">
      <div class="mt_12 settings_main_educate_add_profession">
        <p class="medium_bold_l" :class="[{'mt_16': index !== 0}]">{{$t('a_certificate')}} ({{index + 1}}):</p>
        <div
          class="flex course_add_block_form_up_info course_add_filed_width">
          <div class="info">
            <SelectedComponent
              type="certificate"
              :title="$t('certif_name')"
              :options="listOfCert"
              :selected="selectedCert[index].one"
              @select-item="selectCertif"
              class="p_margin"/>
          </div>
        </div>
      </div>
      <div class="mt_12 settings_main_educate_add_profession">
        <p class="medium_m">{{$t('total_score')}}</p>
        <div
          class="flex course_add_block_form_up_info course_add_filed_width">
          <div class="info">
            <label
              ref="total_score"
              class="m_auto_bt_12"
              :class="[item.total_score !== '' ? 'selected_label' : 'not_selected_label']">{{$t('total_score')}}</label>
            <input
              type="text"
              v-model="item.total_score"
              class="medium_m brdr_r_8"
              @click.stop>
          </div>
        </div>
      </div>
      <div v-if="index !== 0" style="padding-bottom: 16px;" class="">
        <button @click="delLineCertif(index)" class="medium_bold_m bttn-danger course_add_bttn bttn_width bttn brdr_r_8">
          <p>{{$t('delete')}}</p>
        </button>
      </div>
    </div>
    <div>
      <button @click="addLineCertif" class="medium_bold_m bttn-secondary course_add_bttn bttn_width bttn brdr_r_8">
        <p>{{$t('add_certif')}}</p>
      </button>
    </div>
    <button
      @click="stepBack"
      class="medium_bold_s bttn bttn-primary brdr_r_8 settings_main_educate_add_bttn mt_12 mr_16">
      {{$t('back')}}
    </button>
    <button
      @click="sendCertifData"
      class="medium_bold_s bttn bttn-primary brdr_r_8 settings_main_educate_add_bttn mt_12 mr_16">
      {{$t('save')}}
    </button>
  </div>
</template>

<script>
import SelectedComponent from '@/components/select/SelectedComponent'

export default {
  name: 'CertifComponent',
  components: { SelectedComponent },
  props: {
    certificate: Array
  },
  data () {
    return {
      certif: [{
        name: '',
        total_score: 0,
        user: JSON.parse(localStorage.getItem('uData')).id,
        language: ''
      }],
      listOfCert: ['IELTS', 'TOEFL', 'SAT', 'Duolingo', 'ISE', 'FCE', 'CAE', 'CPE', 'BEC', 'TestDaF', 'Goethe-Zertifikat', 'ТРКИ(TRFL)', 'CELI', 'CILS', 'PLIDA', 'DELF-DALF', 'TCF DAP', 'TYS', 'TDS', 'Tomer'],
      langOfCertif: ['Английский', 'Английский', 'Английский', 'Английский', 'Английский', 'Английский', 'Английский', 'Английский', 'Английский', 'Немецкий', 'Немецкий', 'Русский', 'Итальянский', 'Итальянский', 'Итальянский', 'Французский', 'Французский', 'Турецкий', 'Турецкий', 'Турецкий'],
      selectedCert: [{ one: null }, { one: null }, { one: null }],
      slectedRow: null
    }
  },
  watch: {
    certificate: function () {
      this.setCertif()
    }
  },
  methods: {
    setRow (ind) {
      this.slectedRow = ind
    },
    setCertif () {
      this.certif = this.certificate
      this.certif.forEach((element, index) => {
        this.selectedCert[index].one = this.listOfCert.findIndex(obj => obj === element.name)
      })
    },
    selectCertif: function (ind) {
      this.certif[this.slectedRow].name = this.listOfCert[ind]
      this.selectedCert[this.slectedRow].one = ind
      this.certif[this.slectedRow].language = this.langOfCertif[ind]
    },
    addLineCertif () {
      if (this.certif.length <= 3) {
        this.certif.push({
          name: '',
          total_score: 0,
          user: JSON.parse(localStorage.getItem('uData')).id,
          language: ''
        })
      } else {
        this.$emit('warn-toast', `${this.$t('count_of_massive_course')}`)
      }
    },
    delLineCertif (ind) {
      this.certif.splice(ind, 1)
    },
    stepBack () {
      this.$emit('step-back')
    },
    sendCertifData () {
      this.$emit('set-data', this.certif)
    }
  },
  mounted () {
    this.setCertif()
  }
}
</script>
